import QuizIcon from "@mui/icons-material/Quiz";
import BookIcon from "@mui/icons-material/Book";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";

export const getPages = (isTeacher: boolean) => {
  return [
    {
      label: "Início",
      href: "/",
      icon: HomeIcon,
    },
    {
      label: "Desafios",
      href: "/desafios",
      icon: QuizIcon,
    },
    ...(isTeacher
      ? [
          {
            label: "Turmas",
            href: "/turmas",
            icon: BookIcon,
          },
        ]
      : [
          {
            label: "Ranking",
            href: "/ranking",
            icon: MilitaryTechIcon,
          },
        ]),
    {
      label: "Sair",
      href: "logout",
      icon: LogoutIcon,
    },
  ];
};
