import { getChallengeWithResult } from "lib/firebase/challenges";
import { DetailedChallengeWithResult } from "lib/firebase/types";
import { useEffect, useState } from "react";

export default function useDetailedChallengeQuery(
  challengeId: string,
  isTeacher: boolean
) {
  const [data, setData] = useState<DetailedChallengeWithResult | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getChallengeWithResult(challengeId, isTeacher);
        if (res) {
          setData(res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    let mounted = true;

    if (mounted) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [challengeId, isTeacher]);

  return { data, loading };
}
