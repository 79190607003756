import { useAuth } from "contexts/Authenticator";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as RoutesDOM,
} from "react-router-dom";
import { PrivateNavigator } from "./PrivateNavigator";
import About from "pages/About/About";
import Home from "pages/Home/Home";
import { Fragment } from "react/jsx-runtime";
import Challenges from "pages/Challenges/Challenges";
import Challenge from "pages/Challenge/Challenge";
import AvatarSelector from "pages/AvatarSelector/AvatarSelector";
import Ranking from "pages/Ranking/Ranking";
import AddClass from "pages/AddClass/AddClass";
import Class from "pages/Class/Class";
import { CircularProgress } from "@mui/material";

export const Routes = () => {
  const { isLoggedIn, isTeacher, loading } = useAuth();

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <BrowserRouter>
      <Fragment>
        <RoutesDOM>
          {isLoggedIn ? (
            <Route path="/" element={<PrivateNavigator />}>
              <Route path="/" element={<Home />} />
              <Route path="/desafios" element={<Challenges />} />
              <Route path="/desafios/:id" element={<Challenge />} />
              {isTeacher ? (
                <>
                  <Route path="/adicionar-turma" element={<AddClass />} />
                  <Route path="/turmas/:id" element={<Class />} />
                </>
              ) : (
                <>
                  <Route path="/editar-avatar" element={<AvatarSelector />} />
                  <Route path="/ranking" element={<Ranking />} />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          ) : (
            <>
              <Route path="/" element={<About />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </RoutesDOM>
      </Fragment>
    </BrowserRouter>
  );
};
