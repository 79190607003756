import RTERenderer from "components/RTERenderer";
import { QuestionStep as QuestionStepType } from "utils/challengeBuilder";
import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import QuestionInput from "./helpers/QuestionInput";
import { useDialog } from "hooks/useDialog";
import { useNavigate } from "react-router-dom";

interface QuestionStepProps {
  question: QuestionStepType;
  handleStepChange: (step: number) => void;
  currentStep: number;
  handleUpdateStatus: (step: number, answer: string) => void;
  onlyView: boolean;
  predefinedAnswer: string;
}

const QuestionStep = ({
  question,
  handleStepChange,
  currentStep,
  handleUpdateStatus,
  onlyView,
  predefinedAnswer,
}: QuestionStepProps) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState<string>(predefinedAnswer);

  const updateAnswer = (newAnswer: string) => {
    if (onlyView) return;

    setAnswer(newAnswer);
  };

  const isFirstQuestion = currentStep === 0;
  const isLastQuestion = currentStep === 5;

  const handleConcept = () => {
    handleStepChange(1);
  };

  const handleAnswer = async () => {
    if (!onlyView) {
      handleUpdateStatus(currentStep, answer);
    }

    const shouldShowDialog = isFirstQuestion && !onlyView;

    if (isLastQuestion) {
      if (onlyView) {
        navigate("/desafios");
      }
      return;
    }

    if (shouldShowDialog) {
      const isCorrect = answer === question.answer;
      if (isCorrect) {
        openDialog({
          title: "Resposta correta!",
          body: "Parabéns, você acertou a pergunta inicial! Agora você pode ir direto para as questões finais ou revisar o conteúdo antes disso.",
          mainButton: {
            label: "Ir para questões",
            onClick: () => handleStepChange(currentStep + 3),
          },
          secondaryButton: {
            label: "Revisar conteúdo",
            onClick: handleConcept,
          },
        });
      } else {
        openDialog({
          title: "Resposta incorreta",
          body: "Você errou a resposta da pergunta inicial. Não se preocupe, você ainda pode revisar o conteúdo antes de seguir para as próximas perguntas.",
          mainButton: {
            label: "Revisar conteúdo",
            onClick: handleConcept,
          },
        });
      }
      return;
    }

    handleStepChange(currentStep + 1);
  };

  return (
    <Stack spacing={2}>
      <RTERenderer content={question.content} />
      <QuestionInput
        answer={answer}
        setAnswer={updateAnswer}
        options={question.options}
        correctAnswer={onlyView ? question.answer : undefined}
      />
      <Button
        variant="contained"
        onClick={handleAnswer}
        disabled={!answer && !onlyView}
      >
        {isLastQuestion
          ? "Finalizar"
          : onlyView
          ? "Próxima"
          : "Enviar resposta"}
      </Button>
      {currentStep !== 0 && (
        <Button variant="outlined" onClick={handleConcept}>
          Revisar conceito
        </Button>
      )}
    </Stack>
  );
};

export default QuestionStep;
