import { getRankingData } from "lib/firebase/ranking";
import { StudentDb } from "lib/firebase/types";
import { useEffect, useState } from "react";

export default function useRankingQuery() {
  const [data, setData] = useState<{
    sameClass: StudentDb[];
    general: StudentDb[];
  } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRankingData();
        if (res) {
          setData(res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    let mounted = true;

    if (mounted) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return { data, loading };
}
