import { signInWithPopup, GoogleAuthProvider, User } from "firebase/auth";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth, db } from "../lib/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { addStudent } from "lib/firebase/students";
import { isInTeacherList } from "lib/firebase/teachers";

const provider = new GoogleAuthProvider();

type AuthenticatorContextType = {
  signInWithGoogle: () => void;
  isLoggedIn: boolean;
  user: User | null;
  signOut: () => void;
  isTeacher: boolean;
  loading: boolean;
};

export const AuthenticatorContext = createContext<AuthenticatorContextType>({
  signInWithGoogle: () => {},
  isLoggedIn: false,
  user: null,
  signOut: () => {},
  isTeacher: false,
  loading: true,
});

export const Authenticator = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isTeacher, setIsTeacher] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const loadStorageAuth = useCallback(async () => {
    const sessionToken = sessionStorage.getItem("@AuthFirebase:token");
    const rawSessionUser = sessionStorage.getItem("@AuthFirebase:user");

    const sessionUser = rawSessionUser
      ? (JSON.parse(rawSessionUser) as User)
      : null;

    if (!sessionToken || !sessionUser) {
      setLoading(false);
      return;
    }

    const isUserTeacher = await isInTeacherList(sessionUser?.email ?? "");

    setIsTeacher(isUserTeacher);

    setUser(sessionUser);
    setLoading(false);
  }, []);

  useEffect(() => {
    const email = sessionStorage.getItem("@AuthFirebase:email");

    if (!email) {
      return;
    }

    const docRef = doc(db, "students", email);

    const unsubscribe = onSnapshot(docRef, () => {
      loadStorageAuth();
    });

    return () => {
      unsubscribe();
    };
  }, [loadStorageAuth]);

  useEffect(() => {
    loadStorageAuth();
  }, [loadStorageAuth]);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        if (!credential) {
          throw new Error("Google Auth Error");
        }

        const token = credential.accessToken;

        if (!token) {
          throw new Error("Google Auth Error");
        }

        const user = result.user;

        const isUserTeacher = await isInTeacherList(user.email ?? "");

        setIsTeacher(isUserTeacher);

        if (!isUserTeacher) {
          await addStudent({
            email: user.email ?? "",
            name: user.displayName ?? "",
          });
        }

        sessionStorage.setItem("@AuthFirebase:token", token);
        sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
        sessionStorage.setItem("@AuthFirebase:email", user.email ?? "");
        setUser(user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const signOut = () => {
    sessionStorage.clear();
    setUser(null);
  };

  return (
    <AuthenticatorContext.Provider
      value={{
        signInWithGoogle,
        isLoggedIn: !!user && !loading,
        user,
        signOut,
        isTeacher,
        loading,
      }}
    >
      {children}
    </AuthenticatorContext.Provider>
  );
};

export const useAuth = () => useContext(AuthenticatorContext);
