import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import { StudentDb } from "lib/firebase/types";
import React, { FC } from "react";
import { getAvatarSrc } from "utils/avatars";
import { getSamuraiLevel } from "utils/xp";

interface StudentCardProps {
  student: StudentDb;
  position?: number;
}

const StudentCard: FC<StudentCardProps> = ({ student, position }) => {
  const theme = useTheme();

  const samuraiInfo = getSamuraiLevel(student.xp);

  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      borderRadius={10}
      px={2}
      py={1}
      color={theme.palette.primary.contrastText}
      sx={{
        backgroundColor: theme.palette.primary.main,
        border: "3px solid",
        borderColor: theme.palette.primary.dark,
      }}
    >
      <Typography variant="h6">{position}º</Typography>
      <Avatar
        src={student.avatar ? getAvatarSrc(student.avatar) : "/profile.svg"}
        alt={student.name ?? "A placeholder user image"}
        sx={{
          width: 64,
          height: 64,
          border: "5px solid",
          borderColor: theme.palette.primary.dark,
        }}
      />
      <Stack spacing={1} width="100%" alignItems="center">
        <Typography variant="body1">{student.name}</Typography>
        <Typography variant="body2">{samuraiInfo.title}</Typography>
        <Typography variant="body2">
          Nvl {samuraiInfo.level} - {student.xp}/{samuraiInfo.goal} XP
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StudentCard;
