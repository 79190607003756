import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DetailedChallengeWithResult } from "lib/firebase/types";
import { useDialog } from "hooks/useDialog";
import InfoIcon from "@mui/icons-material/Info";

interface ChallengeCardProps {
  detailedChallenge: DetailedChallengeWithResult;
}

const ChallengeCard = ({ detailedChallenge }: ChallengeCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const { challenge, result } = detailedChallenge;

  const bgColor = !!result
    ? theme.palette.primary.dark
    : theme.palette.primary.light;

  const abilities = challenge.ability.split(", ");

  const handleClick = () => {
    const body = !!result ? (
      <Typography>
        Você já realizou esse desafio e obteve <b>{result.obtainedXp}</b> XP!
        Agora, pode revisitar as perguntas e conferir suas respostas anteriores.
        Aproveite para relembrar o que aprendeu, mesmo que não seja possível
        refazer o desafio.
      </Typography>
    ) : (
      <Stack spacing={2}>
        <Typography variant="body1">{challenge.description}</Typography>
        <Chip
          label="Você só pode realizar esse desafio uma única vez!"
          color="secondary"
          icon={<InfoIcon />}
          sx={{ py: 1 }}
        />
      </Stack>
    );

    openDialog({
      title: challenge.title,
      body,
      mainButton: {
        onClick: () => navigate(`/desafios/${challenge.id}`),
        label: "Ir para o desafio",
      },
      secondaryButton: {
        label: "Voltar",
      },
    });
  };

  return (
    <Box
      borderRadius={2}
      component="a"
      onClick={handleClick}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box
        padding={2}
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: bgColor }}
        borderRadius={!!challenge.image ? "16px 16px 0 0" : "16px"}
      >
        <Stack spacing={1}>
          <Typography variant="h6" color={theme.palette.primary.contrastText}>
            {challenge.title}
          </Typography>
          <Stack spacing={1} direction="row">
            {abilities.map((ability, index) => (
              <Chip key={index} label={ability} color="secondary" />
            ))}
          </Stack>
        </Stack>
        {result && (
          <Typography
            variant="body1"
            color={theme.palette.primary.contrastText}
            flexShrink={0}
          >
            +{result?.obtainedXp} XP
          </Typography>
        )}
      </Box>
      {challenge.image && (
        <Box position="relative">
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `linear-gradient(to top, transparent, ${bgColor})`,
            }}
          />
          <img
            src={challenge.image}
            alt={challenge.title}
            style={{
              width: "100%",
              height: 100,
              objectFit: "cover",
              objectPosition: "0% 20%",
              borderBottomRightRadius: 16,
              borderBottomLeftRadius: 16,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChallengeCard;
