import BasePage from "components/BasePage";
import ChallengeCard from "components/ChallengeCard";
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import useChallengesQuery from "queries/useChallengesQuery";
import { DetailedChallengeWithResult } from "lib/firebase/types";
import DialogDescription from "components/DialogDescription";

const Challenges = () => {
  const { data, loading } = useChallengesQuery();

  const [showOnlyUnanswered, setShowOnlyUnanswered] = useState(false);

  const challenges = useMemo(() => {
    const extra = data?.filter((challenge) => !!challenge.data) ?? [];
    const normal = data?.filter((challenge) => !challenge.data) ?? [];

    if (showOnlyUnanswered) {
      return {
        extra: extra?.filter((challenge) => !challenge.result),
        normal: normal?.filter((challenge) => !challenge.result),
      };
    }

    return { extra, normal };
  }, [data, showOnlyUnanswered]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!challenges.extra.length && !challenges.normal.length) {
    return <Typography variant="h4">Nenhum desafio encontrado</Typography>;
  }

  const ChallengesGrid = ({
    challenges,
  }: {
    challenges: DetailedChallengeWithResult[];
  }) => {
    return (
      <Stack spacing={2}>
        {challenges.map((challenge) => (
          <ChallengeCard
            detailedChallenge={challenge}
            key={challenge.challenge.id}
          />
        ))}
      </Stack>
    );
  };

  return (
    <BasePage
      title="Desafios"
      description={
        <DialogDescription
          regular="Nesta tela, você verá todos os desafios disponíveis, organizados entre regulares e especiais, conforme definido pelo seu professor. Cada desafio é baseado em um samurai específico e abrange habilidades matemáticas importantes. Ao entrar em um desafio, você encontrará questões e textos explicativos. Resolver desafios lhe dará XP, dependendo do seu desempenho."
          bold="Lembre-se, cada desafio pode ser feito apenas uma vez, então aproveite ao máximo!"
        />
      }
    >
      <FormControlLabel
        sx={{ alignSelf: "center" }}
        control={
          <Switch
            value={showOnlyUnanswered}
            onChange={(event) => setShowOnlyUnanswered(event.target.checked)}
          />
        }
        label="Mostrar apenas desafios nao respondidos"
      />
      {!!challenges.extra.length && (
        <Stack spacing={2}>
          <Typography variant="h5" alignSelf="center">
            Desafios Especiais
          </Typography>
          <ChallengesGrid challenges={challenges.extra} />
          <Divider />
        </Stack>
      )}
      <ChallengesGrid challenges={challenges.normal} />
    </BasePage>
  );
};

export default Challenges;
