import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { AddClassInput, ClassDb, ClassResult } from "./types";
import { assignTypes, db } from "lib/firebase";
import { getStudentsByClass } from "./ranking";
import { getChallengesWithClassData } from "./challenges";

export const isClassExistent = async (code: string) => {
  const docRef = doc(db, "classes", code);

  const docSnap = await getDoc(docRef);

  return docSnap.exists();
};

export const addClass = async (input: AddClassInput) => {
  const canAddClass = !(await isClassExistent(input.code));

  if (!canAddClass) {
    throw new Error("Já existe uma turma com esse código");
  }

  const docRef = doc(db, "classes", input.code);

  const teacher = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  await setDoc(docRef, {
    ...input,
    teacher,
  });
};

export const getClasses = async () => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const classesQuery = query(
    collection(db, "classes").withConverter(assignTypes<ClassDb>()),
    where("teacher", "==", email)
  );

  const classesDocs = await getDocs(classesQuery);

  const classes = classesDocs.docs.map((doc) => doc.data());

  return classes;
};

export const getClass = async (code: string): Promise<ClassResult | null> => {
  const docRef = doc(db, "classes", code).withConverter(assignTypes<ClassDb>());

  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  const students = await getStudentsByClass(docRef);

  const challenges = await getChallengesWithClassData(code);

  return {
    ...docSnap.data(),
    students,
    challenges,
  };
};
