import { CircularProgress, Divider, Stack, Typography } from "@mui/material";
import BasePage from "components/BasePage";
import StudentCard from "components/StudentCard";
import useRankingQuery from "queries/useRankingQuery";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { StudentDb } from "lib/firebase/types";
import DialogDescription from "components/DialogDescription";

const Ranking = () => {
  const { data, loading } = useRankingQuery();

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const RankList = ({
    category,
    students,
  }: {
    category: string;
    students: StudentDb[];
  }) => {
    return (
      <Stack spacing={2}>
        <Typography variant="h5" align="center">
          {category}
        </Typography>
        <Stack spacing={1}>
          {students.map((student, index) => (
            <StudentCard
              key={student.name}
              student={student}
              position={index + 1}
            />
          ))}
        </Stack>
      </Stack>
    );
  };

  const showSameClass = data.sameClass.length > 0;

  return (
    <BasePage
      title="Ranking"
      description={
        <DialogDescription
          regular="Nesta tela, você pode acompanhar seu progresso em dois rankings diferentes: um geral, que inclui todos os alunos cadastrados no sistema, e outro específico para sua turma."
          bold="Veja sua posição e continue avançando em sua jornada de aprendizado!"
        />
      }
    >
      <Grid container columnSpacing={1}>
        <Grid
          xs={12}
          md={showSameClass ? 6 : 12}
          alignSelf={showSameClass ? "unset" : "center"}
          minWidth={showSameClass ? "unset" : "384px"}
        >
          <RankList category="Geral" students={data.general} />
          <Divider sx={{ my: 4, display: { xs: "block", md: "none" } }} />
        </Grid>
        {showSameClass && (
          <Grid xs={12} md={6}>
            <RankList category="Sua turma" students={data.sameClass} />
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

export default Ranking;
