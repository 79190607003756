import { DetailedChallenge, Explanation, Question } from "lib/contentful/types";

enum StepType {
  Question = "question",
  Explanation = "explanation",
}

export interface QuestionStep extends Question {
  type: StepType.Question;
  available: boolean;
}

export interface ExplanationStep extends Explanation {
  type: StepType.Explanation;
  available: boolean;
}

export type ChallengeStep = QuestionStep | ExplanationStep;

export const getChallengeSteps = (
  challenge: DetailedChallenge
): ChallengeStep[] => {
  return [
    {
      ...challenge.mainQuestion,
      type: StepType.Question,
      available: true,
    },
    {
      ...challenge.concept,
      type: StepType.Explanation,
      available: true,
    },
    {
      ...challenge.content,
      type: StepType.Explanation,
      available: true,
    },
    ...challenge.questions.map((question) => ({
      ...question,
      type: StepType.Question as StepType.Question,
      available: false,
    })),
  ];
};
