import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Typography } from "@mui/material";
import React from "react";
import { Document, MARKS } from "@contentful/rich-text-types";

interface RTERendererProps {
  content: Document;
}

const RTERenderer = ({ content }: RTERendererProps) => {
  return (
    <>
      {documentToReactComponents(content, {
        renderText: (text) => (
          <Typography fontWeight="inherit" display="inline">
            {text}
          </Typography>
        ),
        renderMark: {
          [MARKS.BOLD]: (text) => (
            <Typography variant="body1" fontWeight="bold" display="inline">
              {text}
            </Typography>
          ),
        },
      })}
    </>
  );
};

export default RTERenderer;
