import {
  Avatar,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { StudentProfile } from "lib/firebase/types";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ProfileCardProps {
  student: StudentProfile;
}

const ProfileCard: FC<ProfileCardProps> = ({ student }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { firestore, samuraiInfo } = student;

  if (!firestore) {
    return <Typography>Erro ao carregar perfil</Typography>;
  }

  const avatar = firestore.avatar ?? "";
  const progress = (firestore.xp / samuraiInfo.goal) * 100;

  return (
    <Stack
      spacing={2}
      alignItems="center"
      alignSelf="center"
      width="90%"
      borderRadius={4}
      p={2}
      border={`2px solid ${theme.palette.primary.dark}`}
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Typography variant="h4" color={theme.palette.primary.contrastText}>
        {student?.firestore?.name}
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        width="100%"
        alignItems="center"
        spacing={2}
      >
        <Tooltip title="Clique no avatar se desejar trocá-lo" placement="right">
          <Avatar
            src={`avatars/${avatar}.jpeg`}
            component="a"
            sx={{
              width: 160,
              height: 160,
              cursor: "pointer",
              border: "5px solid",
              borderColor: theme.palette.primary.dark,
            }}
            onClick={() => navigate("/editar-avatar")}
          />
        </Tooltip>
        <Stack spacing={1} py={1} width="100%">
          <Typography
            variant="body1"
            color={theme.palette.primary.contrastText}
            alignSelf="center"
          >
            {samuraiInfo.title}
          </Typography>
          <LinearProgress
            value={progress}
            variant="determinate"
            color="secondary"
            sx={{ height: "20px", borderRadius: 10 }}
          />
          <Typography
            variant="body1"
            color={theme.palette.primary.contrastText}
            alignSelf="center"
          >
            Nvl {samuraiInfo.level} - {firestore.xp}/{samuraiInfo.goal} XP
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileCard;
