import { ThemeProvider } from "@mui/material";
import { Authenticator } from "./contexts/Authenticator";
import { Routes } from "./routes/Navigator";
import theme from "./components/theme";
import { DialogProvider } from "hooks/useDialog";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <Authenticator>
          <Routes />
        </Authenticator>
      </DialogProvider>
    </ThemeProvider>
  );
};

export default App;
