import Dialog from "components/Dialog";
import { createContext, useContext, useState } from "react";

export type DialogContent = {
  title: string;
  body: React.ReactNode;
  mainButton?: {
    label: string;
    onClick?: () => void;
  };
  secondaryButton?: {
    label: string;
    onClick?: () => void;
  };
};

type VisibleDialogContent = DialogContent & {
  visible: boolean;
};

interface ContextProps {
  dialog: VisibleDialogContent;
  openDialog: (content: DialogContent) => void;
  closeDialog: () => void;
}

const initialState: VisibleDialogContent = {
  title: "",
  body: "",
  visible: false,
  mainButton: undefined,
  secondaryButton: undefined,
};

const DialogContext = createContext<ContextProps>({
  dialog: initialState,
  openDialog: () => {},
  closeDialog: () => {},
});

export const DialogProvider = ({ ...props }) => {
  const [dialog, setDialog] = useState<VisibleDialogContent>(initialState);

  const openDialog = (content: DialogContent) => {
    setDialog({ ...content, visible: true });
  };

  const closeDialog = () => {
    setDialog(initialState);
  };

  return (
    <DialogContext.Provider value={{ dialog, openDialog, closeDialog }}>
      {props.children}
      <Dialog />
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const { dialog, openDialog, closeDialog } = useContext(DialogContext);
  return { dialog, openDialog, closeDialog };
};
