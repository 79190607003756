import {
  collection,
  DocumentReference,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { assignTypes, db } from "lib/firebase";
import { ClassDb, StudentDb } from "./types";
import { getStudent } from "./students";

export const getAllStudents = async () => {
  const studentsQuery = query(
    collection(db, "students").withConverter(assignTypes<StudentDb>()),
    orderBy("xp", "desc")
  );

  const studentsRef = await getDocs(studentsQuery);

  const students = studentsRef.docs.map((doc) => doc.data());

  return students;
};

export const getStudentsByClass = async (
  classRef: DocumentReference<ClassDb>
) => {
  const studentsQuery = query(
    collection(db, "students").withConverter(assignTypes<StudentDb>()),
    where("class", "==", classRef),
    orderBy("xp", "desc")
  );

  const studentsRef = await getDocs(studentsQuery);

  const students = studentsRef.docs.map((doc) => doc.data());

  return students;
};

export const getStudentsFromSameClass = async () => {
  const student = await getStudent();

  if (!student) {
    throw new Error("Student not found");
  }

  if (!student.class) {
    return [];
  }

  const result = await getStudentsByClass(student.class);

  return result;
};

export const getRankingData = async () => {
  const general = await getAllStudents();
  const sameClass = await getStudentsFromSameClass();

  return { general, sameClass };
};
