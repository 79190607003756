import { Box } from "@mui/material";
import { useDialog } from "hooks/useDialog";
import React, { FC } from "react";
import { imageByTitle, rankSettings } from "utils/badges";

interface BadgeProps {
  count: number;
  type: "Desafios" | "Exercícios" | "Experiência";
  description?: React.ReactNode;
}

const Medal: FC<BadgeProps> = ({ count, type, description }) => {
  const { openDialog } = useDialog();
  const rank = Math.ceil(count / 20) * 20;
  const settings =
    rankSettings[rank as keyof typeof rankSettings] ?? rankSettings["20"];

  const icon = `badges/${imageByTitle[type]}/${settings.image}.png`;

  const handleClick = () => {
    if (!description) return;

    openDialog({
      title: `${type} - ${settings.title}`,
      body: description,
      mainButton: {
        label: "Ok",
      },
    });
  };

  return (
    <Box
      component="img"
      onClick={handleClick}
      src={icon}
      alt={`Medalha de ${settings.title}`}
      style={{
        width: "25%",
        height: "auto",
        backgroundColor: settings.backgroundColor,
        border: `5px solid ${settings.border}`,
        borderRadius: "100%",
        padding: 8,
        cursor: description ? "pointer" : "default",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      }}
    />
  );
};

export default Medal;
