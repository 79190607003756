import React from "react";
import { ExplanationStep as ExplanationStepType } from "utils/challengeBuilder";
import { Button, Stack } from "@mui/material";
import RTERenderer from "components/RTERenderer";

interface ExplanationStepProps {
  explanation: ExplanationStepType;
  handleStepChange: (step: number) => void;
  currentStep: number;
  maxStep: number;
}

const ExplanationStep = ({
  explanation,
  handleStepChange,
  currentStep,
  maxStep,
}: ExplanationStepProps) => {
  const isConcept = currentStep === 1;

  const nextStep = isConcept
    ? currentStep + 1
    : Math.max(currentStep + 1, maxStep);

  return (
    <Stack spacing={4}>
      <RTERenderer content={explanation.content} />
      <Button variant="contained" onClick={() => handleStepChange(nextStep)}>
        {isConcept ? "Próxima" : "Ir para questões"}
      </Button>
      {!isConcept && (
        <Button variant="outlined" onClick={() => handleStepChange(1)}>
          Revisar conceito
        </Button>
      )}
    </Stack>
  );
};

export default ExplanationStep;
