import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import ChallengeStepper from "./steps/ChallengeStepper";
import { useParams } from "react-router-dom";
import BasePage from "components/BasePage";
import useDetailedChallengeQuery from "queries/useDetailedChallengeQuery";
import { useAuth } from "contexts/Authenticator";

const Challenge = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { isTeacher } = useAuth();
  const theme = useTheme();

  const { data, loading } = useDetailedChallengeQuery(id, isTeacher);

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <Typography>Desafio não encontrado</Typography>;
  }

  const answers = !!data.result
    ? {
        main: data.result.answers.main.answer,
        first: data.result.answers.first.answer,
        second: data.result.answers.second.answer,
        third: data.result.answers.third.answer,
      }
    : {
        main: "",
        first: "",
        second: "",
        third: "",
      };

  const onlyView = isTeacher || !!data.result;

  return (
    <BasePage title={data.challenge.title}>
      <ChallengeStepper
        challenge={data.challenge}
        onlyView={onlyView}
        answers={answers}
      />
      {onlyView && (
        <Box
          sx={{
            border: `3px solid ${theme.palette.primary.main}`,
            p: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Typography variant="body1">
            {isTeacher
              ? "Ao acessar este desafio, você verá todas as perguntas acompanhadas das respostas corretas. Use essa visão geral para revisar o conteúdo e acompanhar o desempenho dos alunos."
              : "Você já respondeu a este desafio. Agora, ao acessá-lo, verá as perguntas e suas respostas anteriores. O gabarito está marcado de verde. Não será possível alterar ou enviar novas respostas."}
          </Typography>
        </Box>
      )}
    </BasePage>
  );
};

export default Challenge;
