import { getClass } from "lib/firebase/classes";
import { ClassResult } from "lib/firebase/types";
import { useCallback, useEffect, useState } from "react";

export default function useClassQuery(classId: string) {
  const [data, setData] = useState<ClassResult | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getClass(classId);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [classId]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [fetchData]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, refetch };
}
