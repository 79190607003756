import { Button, TextField } from "@mui/material";
import BasePage from "components/BasePage";
import { addClass } from "lib/firebase/classes";
import { AddClassInput } from "lib/firebase/types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClassCode } from "utils/classes";

const initialClass: AddClassInput = {
  name: "",
  school: "",
  code: "",
};

const AddClass = () => {
  const navigate = useNavigate();
  const [classData, setClassData] = useState(initialClass);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async () => {
    try {
      await addClass({
        ...classData,
        code: getClassCode(classData.name, classData.school),
      });
      navigate("/turmas");
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <BasePage
      title="Adicionar Turma"
      description="Nesta tela, você pode criar uma nova turma ao preencher o nome e o colégio. Após isso, um código exclusivo será gerado, que deverá ser compartilhado com seus alunos para que eles possam se juntar à turma."
    >
      <TextField
        label="Nome"
        placeholder="5o A"
        variant="filled"
        onChange={(event) =>
          setClassData({ ...classData, name: event.target.value })
        }
        onBlur={() => setError(null)}
        value={classData.name}
      />
      <TextField
        label="Colégio"
        placeholder="Colégio A"
        variant="filled"
        onChange={(event) =>
          setClassData({ ...classData, school: event.target.value })
        }
        onBlur={() => setError(null)}
        value={classData.school}
      />
      <TextField
        label="Código"
        value={getClassCode(classData.name, classData.school)}
        disabled
        variant="filled"
        helperText={error}
        error={!!error}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!classData.name || !classData.school}
        onClick={onSubmit}
      >
        Adicionar turma
      </Button>
    </BasePage>
  );
};

export default AddClass;
