export interface SamuraiLevelInfo {
  level: number;
  title: string;
  goal: number;
}

const samuraiLevels: SamuraiLevelInfo[] = [
  { level: 1, title: "Aprendiz", goal: 15 },
  { level: 2, title: "Iniciado do Caminho", goal: 30 },
  { level: 3, title: "Discípulo da Lâmina", goal: 45 },
  { level: 4, title: "Noviço Guerreiro", goal: 75 },
  { level: 5, title: "Guardião da Honra", goal: 120 },
  { level: 6, title: "Escolhido do Destino", goal: 195 },
  { level: 7, title: "Portador da Katana", goal: 315 },
  { level: 8, title: "Cavaleiro do Sol Nascente", goal: 510 },
  { level: 9, title: "Protetor dos Fracos", goal: 825 },
  { level: 10, title: "Espada Divina", goal: 1335 },
  { level: 11, title: "Lutador de Destaque", goal: 2160 },
  { level: 12, title: "Executor Implacável", goal: 3495 },
  { level: 13, title: "Samurai Virtuoso", goal: 5655 },
  { level: 14, title: "Campeão dos Oprimidos", goal: 9150 },
  { level: 15, title: "Mestre da Espada", goal: 14805 },
  { level: 16, title: "Guardião da Paz", goal: 23955 },
  { level: 17, title: "Lâmina da Justiça", goal: 38760 },
  { level: 18, title: "Herói do Povo", goal: 62715 },
  { level: 19, title: "Senhor da Guerra", goal: 101475 },
  { level: 20, title: "Lenda Viva", goal: 0 }, // Max level, no further goal
];

export const initialSamuraiLevel = samuraiLevels[0];

export const getSamuraiLevel = (
  xp: number
): {
  level: number;
  title: string;
  goal: number;
} => {
  let levelInfo = samuraiLevels[0]; // Default to level 1

  for (let i = 0; i < samuraiLevels.length; i++) {
    if (xp < samuraiLevels[i].goal) {
      levelInfo = samuraiLevels[i];
      break;
    }
    levelInfo = samuraiLevels[i];
  }

  return levelInfo;
};
