import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import BasePage from "components/BasePage";
import { DetailedChallengeWithClassData, StudentDb } from "lib/firebase/types";
import useClassQuery from "queries/useClassQuery";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSamuraiLevel } from "utils/xp";
import Table from "components/Table";
import { assignChallenge, unassignChallenge } from "lib/firebase/challenges";
import { generateStudentReport, getClassReportData } from "lib/firebase/report";

const Class = () => {
  const { id = "" } = useParams<{ id: string }>();
  const theme = useTheme();

  const studentColumns: GridColDef<StudentDb>[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      headerClassName: "data-grid-header",
    },
    {
      field: "xp",
      headerName: "Nível",
      valueGetter: (_value, row) => getSamuraiLevel(row.xp).level,
      flex: 1,
      headerClassName: "data-grid-header",
    },
    {
      field: "report",
      headerName: "Relatório",
      flex: 1,
      headerClassName: "data-grid-header",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            onClick={() => generateStudentReport(row.email)}
            disabled={row.xp === 0}
          >
            Baixar
          </Button>
        );
      },
    },
  ];

  const challengeColumns: GridColDef<DetailedChallengeWithClassData>[] = [
    {
      field: "title",
      headerName: "Título",
      flex: 2,
      valueGetter: (_value, row) => row.challenge.title,
      headerClassName: "data-grid-header",
    },
    {
      field: "ability",
      headerName: "Habilidades",
      flex: 1,
      valueGetter: (_value, row) => row.challenge.ability,
      headerClassName: "data-grid-header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (_value, row) => (row.data ? "Especial" : "Regular"),
      headerClassName: "data-grid-header",
    },
    {
      field: "updateStatus",
      headerName: "",
      headerClassName: "data-grid-header",
      flex: 2,
      renderCell: ({ row }) => {
        const isAssigned = !!row.data;

        const onClick = async (event: any) => {
          event.stopPropagation();
          isAssigned
            ? await unassignChallenge(row.challenge.id, id)
            : await assignChallenge(row.challenge.id, id);
          refetch();
        };

        return (
          <Button variant="contained" onClick={onClick}>
            Transformar em {isAssigned ? "regular" : "especial"}
          </Button>
        );
      },
    },
  ];

  const { data, loading, refetch } = useClassQuery(id);
  const navigate = useNavigate();

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <Typography>Desafio não encontrado</Typography>;
  }

  const showReport = data.students.some((student) => student.xp > 0);

  return (
    <BasePage
      title={data.name}
      description="Nesta tela, você encontrará uma visão detalhada da sua turma. Visualize uma tabela com todos os alunos e baixe relatórios individuais ou da turma inteira para acompanhar o progresso. Além disso, você pode explorar os desafios disponíveis e decidir quais serão considerados especiais (valerão 2x mais) e quais serão regulares, ajustando o aprendizado conforme as necessidades da turma."
    >
      <Table
        rows={data.students}
        columns={studentColumns}
        getRowId={(row) => row.name}
      />
      <Box
        textAlign="center"
        p={2}
        borderRadius={2}
        sx={{
          border: "1px solid",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Typography>
          Envie o código <b>{data.code}</b> para seus alunos entrarem na turma.
        </Typography>
      </Box>
      <Typography variant="h5" alignSelf="center">
        Desafios
      </Typography>
      <Table
        rows={data.challenges}
        columns={challengeColumns}
        getRowId={(row) => row.challenge.id}
        onRowClick={({ row }) => {
          navigate(`/desafios/${row.challenge.id}`);
        }}
        sortModel={[
          {
            field: "ability",
            sort: "asc",
          },
        ]}
      />
      {showReport && (
        <Button onClick={() => getClassReportData(id)} variant="outlined">
          BAIXAR RELATORIO
        </Button>
      )}
    </BasePage>
  );
};

export default Class;
