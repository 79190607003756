import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import {
  ChallengeClassData,
  ChallengeResult,
  ClassDb,
  DetailedChallengeWithClassData,
  DetailedChallengeWithResult,
} from "./types";
import { assignTypes, db } from "lib/firebase";
import { getChallenges, getDetailedChallenge } from "lib/contentful/challenges";
import { combineChallenges, combineChallengesForClass } from "utils/challenges";
import { addXp, getStudent } from "./students";

const isChallengeExtra = async (
  challengeId: string,
  classRef: DocumentReference<ClassDb>
) => {
  const challengeRef = doc(classRef, "challenges", challengeId);

  const challengeDoc = await getDoc(challengeRef);

  return challengeDoc.exists();
};

export const answerChallenge = async (result: ChallengeResult) => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const student = await getStudent();

  const isExtra = student?.class
    ? await isChallengeExtra(result.id, student.class)
    : false;

  const challengeRef = doc(db, "students", email, "challenges", result.id);

  await setDoc(challengeRef, { ...result, isExtra });

  const xpToAdd = isExtra ? result.obtainedXp * 2 : result.obtainedXp;

  await addXp(xpToAdd);
};

export const getChallengesWithResults = async (): Promise<
  DetailedChallengeWithResult[]
> => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const challengesQuery = query(
    collection(db, "students", email, "challenges").withConverter(
      assignTypes<ChallengeResult>()
    )
  );

  const student = await getStudent();

  const classData = student?.class
    ? await getClassChallengesData(student?.class)
    : undefined;

  const challengesDocs = await getDocs(challengesQuery);

  const results = challengesDocs.docs.map((doc) => doc.data());

  const content = await getChallenges();

  const combinedChallenges = combineChallenges({ content, results, classData });

  return combinedChallenges.sort((a, b) => {
    return a.challenge.ability.localeCompare(b.challenge.ability);
  });
};

export const getClassChallengesData = async (
  classRef: DocumentReference<ClassDb>
) => {
  const challengesQuery = query(
    collection(classRef, "challenges").withConverter(
      assignTypes<ChallengeClassData>()
    )
  );

  const challengesDocs = await getDocs(challengesQuery);

  const data = challengesDocs.docs.map((doc) => doc.data());
  return data;
};

export const getChallengesWithClassData = async (
  classCode: string
): Promise<DetailedChallengeWithClassData[]> => {
  const classRef = doc(db, "classes", classCode).withConverter(
    assignTypes<ClassDb>()
  );
  const data = await getClassChallengesData(classRef);

  const content = await getChallenges();

  const combinedChallenges = combineChallengesForClass(content, data);

  return combinedChallenges;
};

export const assignChallenge = async (
  challengeId: string,
  classCode: string
) => {
  const challengeRef = doc(db, "classes", classCode, "challenges", challengeId);

  await setDoc(
    challengeRef,
    { id: challengeId, addedAt: new Date() },
    { merge: true }
  );
};

export const unassignChallenge = async (
  challengeId: string,
  classCode: string
) => {
  const challengeRef = doc(db, "classes", classCode, "challenges", challengeId);

  await deleteDoc(challengeRef);
};

export const getChallengeWithResult = async (
  challengeId: string,
  isTeacher = false
): Promise<DetailedChallengeWithResult | undefined> => {
  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const challenge = await getDetailedChallenge(challengeId);

  if (!challenge) {
    return;
  }

  const challengeRef = doc(
    db,
    "students",
    email,
    "challenges",
    challengeId
  ).withConverter(assignTypes<ChallengeResult>());

  const challengeDoc = await getDoc(challengeRef);

  const result = challengeDoc.data();

  return { challenge, result };
};
