import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React, { FC } from "react";
import { ptBR } from "@mui/x-data-grid/locales";
import { Paper, useTheme } from "@mui/material";

const Table: FC<DataGridProps> = ({ sx, ...props }) => {
  const theme = useTheme();

  const addPointer = !!props.onRowClick;

  return (
    <Paper sx={{ height: "300px", width: "100%" }}>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        autoPageSize
        disableRowSelectionOnClick
        sx={{
          "& .data-grid-header": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          },
          cursor: addPointer ? "pointer" : "default",
          ...sx,
        }}
        {...props}
      />
    </Paper>
  );
};

export default Table;
