import { Stack, Typography, useTheme } from "@mui/material";
import { StudentStats } from "lib/firebase/types";
import React, { FC, useCallback } from "react";
import Medal from "./Medal";
import pluralize from "pluralize";
import { rankSettings } from "utils/badges";

interface StatsProps {
  stats: StudentStats;
}

const Stats: FC<StatsProps> = ({ stats }) => {
  const theme = useTheme();
  const getNextRank = useCallback(
    ({
      value,
      total,
      percentage,
    }: {
      value: number;
      total: number;
      percentage: number;
    }) => {
      if (percentage >= 80) return;

      const rank = Math.ceil(percentage / 20) * 20;

      // get next number multiple of 20 after rank
      const val = Object.keys(rankSettings)
        .map(Number)
        .find((key) => key > rank) as number;

      const necessary = Math.ceil((val / 100) * total);

      return necessary - value;
    },
    []
  );

  const showContent =
    !!stats.challenges.value ||
    !!stats.questions.value ||
    !!stats.xp.sameClassPercentage;

  const formatPercentage = (percentage: number) => {
    return percentage.toFixed(0).replace(".", ",") + "%";
  };

  const Content = () => {
    return (
      <Stack
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        {!!stats.challenges.value && (
          <Medal
            type="Desafios"
            count={stats.challenges.percentage}
            description={
              <Typography>
                Você já respondeu <b>{stats.challenges.value}</b>{" "}
                {pluralize("desafio", stats.challenges.value)}. Isso equivale a{" "}
                <b>{formatPercentage(stats.challenges.percentage)}</b> dos
                desafios da plataforma. Resolva mais{" "}
                <b>{getNextRank(stats.challenges)}</b>{" "}
                {pluralize("desafio", getNextRank(stats.challenges))} para
                ganhar a próxima medalha.
              </Typography>
            }
          />
        )}
        {!!stats.questions.value && (
          <Medal
            type="Exercícios"
            count={stats.questions.percentage}
            description={
              <Typography>
                Você respondeu corretamente <b>{stats.questions.value}</b>{" "}
                {pluralize("exercício", stats.questions.value)}. Isso equivale a{" "}
                <b>{formatPercentage(stats.questions.percentage)}</b> dos
                exercícios já respondidos. Resolva mais exercícios corretamente
                para ganhar mais experiência!
              </Typography>
            }
          />
        )}
        {!!stats.xp.sameClassPercentage && (
          <Medal
            type="Experiência"
            count={stats.xp.generalPercentage}
            description={
              <Typography>
                Você possui mais XP que{" "}
                <b>{formatPercentage(stats.xp.generalPercentage)}</b> dos alunos
                do sistema e{" "}
                <b>{formatPercentage(stats.xp.sameClassPercentage)}</b> dos
                alunos de sua turma.
              </Typography>
            }
          />
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5" align="center">
        Medalhas
      </Typography>
      {showContent ? (
        <Content />
      ) : (
        <Typography alignSelf="center" color={theme.palette.primary.main}>
          Nenhuma medalha ainda. Faça alguns desafios e volte aqui!
        </Typography>
      )}
    </Stack>
  );
};

export default Stats;
