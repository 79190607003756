export const rankSettings = {
  20: {
    backgroundColor: "#cd7f32",
    title: "Bronze",
    image: "bronze",
    border: "#965b24",
  },
  40: {
    backgroundColor: "#c0c0c0",
    title: "Prata",
    image: "silver",
    border: "#808080",
  },
  60: {
    backgroundColor: "#FCE979",
    title: "Ouro",
    image: "gold",
    border: "#FFD700",
  },
  80: {
    backgroundColor: "#b9f2ff",
    title: "Diamante",
    image: "diamond",
    border: "#0495B5",
  },
  100: {
    backgroundColor: "#B196FA",
    title: "Lendário",
    image: "legend",
    border: "#653F8B",
  },
};

export const imageByTitle: Record<string, string> = {
  Desafios: "katana",
  Exercícios: "koi",
  Experiência: "helmet",
};
