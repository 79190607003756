import BasePage from "components/BasePage";
import { useAuth } from "contexts/Authenticator";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import PageContainer from "components/PageContainer";
import Grid from "@mui/material/Unstable_Grid2";
import Medal from "components/Medal";

const About = () => {
  const { signInWithGoogle } = useAuth();
  const theme = useTheme();

  const TextCard = ({ text }: { text: string }) => (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.dark}`,
        borderRadius: 2,
        p: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="body1" color={theme.palette.primary.contrastText}>
        {text}
      </Typography>
    </Box>
  );

  return (
    <PageContainer m={-1}>
      <BasePage showBackButton={false} alignItems="center">
        <img
          src="logo.png"
          alt="Bushido Logo"
          style={{
            width: 400,
            objectFit: "cover",
          }}
        />
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid xs={12} md={6}>
            <TextCard text="Prepare-se para enfrentar desafios que vão testar suas habilidades matemáticas. Cada questão que você resolve é um passo para se tornar um verdadeiro mestre." />
          </Grid>
          <Grid
            xs={12}
            md={6}
            alignContent="center"
            textAlign="center"
            display={{ xs: "none", md: "unset" }}
          >
            <img
              src="swordglass.png"
              alt="Espada samurai"
              style={{
                width: "300px",
                objectFit: "cover",
                opacity: 0.8,
              }}
            />
          </Grid>
          <Grid xs={12} md={6} display="flex">
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Medal count={40} type="Experiência" />
              <Medal count={60} type="Desafios" />
              <Medal count={80} type="Exercícios" />
            </Box>
          </Grid>
          <Grid xs={12} md={6} alignContent="center">
            <TextCard text="À medida que você avança, ganhará experiência, subirá de nível e conquistará medalhas, tudo enquanto explora um mundo repleto de tradições e sabedoria dos antigos samurais." />
          </Grid>
        </Grid>
        <Button
          onClick={signInWithGoogle}
          variant="contained"
          color="primary"
          size="large"
        >
          Iniciar Jornada
        </Button>
      </BasePage>
    </PageContainer>
  );
};

export default About;
