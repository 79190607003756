import { Button, CircularProgress, Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ClassDb } from "lib/firebase/types";
import useClassesQuery from "queries/useClassesQuery";
import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "components/Table";

const columns: GridColDef<ClassDb>[] = [
  {
    field: "code",
    headerName: "Código",
    flex: 1,
    headerClassName: "data-grid-header",
  },
  {
    field: "name",
    headerName: "Nome",
    flex: 1,
    headerClassName: "data-grid-header",
  },
  {
    field: "school",
    headerName: "Colégio",
    flex: 1,
    headerClassName: "data-grid-header",
  },
];

const Classes = () => {
  const { data, loading } = useClassesQuery();
  const navigate = useNavigate();

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <div>No data</div>;
  }
  return (
    <>
      <Paper sx={{ height: "300px" }}>
        <Table
          rows={data}
          columns={columns}
          getRowId={(row) => row.code}
          onRowClick={(row) => {
            navigate(`/turmas/${row.row.code}`);
          }}
        />
      </Paper>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/adicionar-turma")}
      >
        Adicionar nova turma
      </Button>
    </>
  );
};

export default Classes;
