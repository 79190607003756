import { Button, CircularProgress } from "@mui/material";
import JoinClass from "components/JoinClass";
import ProfileCard from "components/ProfileCard";
import Stats from "components/Stats";
import { generateStudentReport } from "lib/firebase/report";
import useProfileQuery from "queries/useProfileQuery";
import useStatsQuery from "queries/useStatsQuery";
import React from "react";

const StudentHome = () => {
  const { data: user, loading, refetch } = useProfileQuery();
  const { data: stats, loading: statsLoading } = useStatsQuery();

  if (loading || statsLoading) {
    return <CircularProgress />;
  }

  if (!user?.firestore) {
    return null;
  }

  const showReportButton = user.firestore.xp > 0;

  return (
    <>
      <ProfileCard student={user} />
      {!user?.firestore?.class && <JoinClass refetch={refetch} />}
      {stats && <Stats stats={stats} />}
      {showReportButton && (
        <Button onClick={() => generateStudentReport()} variant="outlined">
          BAIXAR RELATORIO
        </Button>
      )}
    </>
  );
};

export default StudentHome;
