import React, { useMemo, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import { useAuth } from "contexts/Authenticator";
import { getPages } from "utils/pages";

const Header = () => {
  const navigate = useNavigate();
  const { isTeacher, signOut } = useAuth();
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };

  const handleCloseNavMenu = () => {
    setDrawerOpen(false);
  };

  const handleMenuOption = (page: string) => {
    if (page === "logout") {
      signOut();
      return;
    }
    navigate(page);
    handleCloseNavMenu();
  };

  const pages = useMemo(() => getPages(isTeacher), [isTeacher]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="a"
            onClick={() => navigate("/")}
            sx={{
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
            }}
          >
            <img
              src="logo.png"
              alt="Bushido Logo"
              style={{
                width: 120,
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              onClose={() => setDrawerOpen(false)}
              open={drawerOpen}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  py: 2,
                  justifyContent: "space-evenly",
                  px: 2,
                  pt: -2,
                },
              }}
            >
              <img
                src="sword.png"
                alt="Espada"
                style={{
                  width: "auto",
                  height: 200,
                  objectFit: "contain",
                }}
              />
              <Stack direction="column" spacing={2}>
                {pages.map((page) => (
                  <Button
                    key={page.label}
                    onClick={() => handleMenuOption(page.href)}
                    sx={{ my: 1 }}
                    startIcon={<page.icon />}
                    variant="text"
                    size="large"
                  >
                    {page.label}
                  </Button>
                ))}
              </Stack>
              <img
                src="sword.png"
                alt="Espada"
                style={{
                  width: "auto",
                  height: 200,
                  objectFit: "contain",
                  rotate: "180deg",
                }}
              />
            </Drawer>
          </Box>
          <Box
            component="a"
            onClick={() => navigate("/")}
            sx={{
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
            }}
          >
            <img
              src="logo.png"
              alt="Bushido Logo"
              style={{
                width: 120,
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={() => handleMenuOption(page.href)}
                sx={{ m: 2, color: "white" }}
                startIcon={<page.icon />}
              >
                {page.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
