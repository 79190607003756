import { DetailedChallenge } from "lib/contentful/types";
import { ChallengeClassData, ChallengeResult } from "lib/firebase/types";

export const combineChallenges = ({
  content,
  results,
  classData,
}: {
  content: DetailedChallenge[];
  results: ChallengeResult[];
  classData?: ChallengeClassData[];
}) => {
  return content.map((challenge) => {
    const result = results.find((result) => result.id === challenge.id);
    const data = classData?.find((d) => d.id === challenge.id);

    return {
      challenge,
      result,
      data,
    };
  });
};

export const combineChallengesForClass = (
  content: DetailedChallenge[],
  classData: ChallengeClassData[]
) => {
  return content.map((challenge) => {
    const data = classData.find((d) => d.id === challenge.id);

    return {
      challenge,
      data,
    };
  });
};
