import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useMemo } from "react";

interface QuestionInputProps {
  options: string[];
  answer: string;
  setAnswer: (answer: string) => void;
  correctAnswer?: string;
}

const QuestionInput = ({
  options,
  answer,
  setAnswer,
  correctAnswer,
}: QuestionInputProps) => {
  const randomOptions = useMemo(
    () => options?.sort(() => Math.random() - 0.5),
    [options]
  );

  return (
    <RadioGroup value={answer} onChange={(e) => setAnswer(e.target.value)}>
      {randomOptions.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option}
          label={option}
          control={<Radio />}
          sx={correctAnswer === option ? { color: "green" } : {}}
        />
      ))}
    </RadioGroup>
  );
};

export default QuestionInput;
