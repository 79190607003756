import {
  QuestionStep as QuestionStepType,
  ExplanationStep as ExplanationStepType,
  getChallengeSteps,
} from "utils/challengeBuilder";
import { Paper, Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import QuestionStep from "./QuestionStep";
import ExplanationStep from "./ExplanationStep";
import { useNavigate } from "react-router-dom";
import {
  ChallengeAnswers,
  getChallengeResultMessage,
  getTitlePerStep,
  keyPerStep,
} from "./helpers/utils";
import { answerChallenge } from "lib/firebase/challenges";
import { QuestionResult } from "lib/firebase/types";
import { DetailedChallenge } from "lib/contentful/types";
import { Timestamp } from "firebase/firestore";
import { useDialog } from "hooks/useDialog";

interface ChallengeStepperProps {
  challenge: DetailedChallenge;
  onlyView: boolean;
  answers: ChallengeAnswers;
}

const wrongAnswerXp = 1;
const correctAnswerXp = 5;

const ChallengeStepper = ({
  challenge,
  onlyView,
  answers: initialAnswers,
}: ChallengeStepperProps) => {
  const navigate = useNavigate();
  const [step, setStep] = useState({ max: 0, current: 0 });
  const [userAnswers, setUserAnswers] =
    useState<ChallengeAnswers>(initialAnswers);
  const { openDialog } = useDialog();

  const steps = useMemo(() => getChallengeSteps(challenge), [challenge]);

  const handleUpdateStatus = async (step: number, answer: string) => {
    const key = keyPerStep[step];
    const newStatus = { ...userAnswers, [key]: answer };
    setUserAnswers((prev) => ({ ...prev, [key]: answer }));
    if (Object.values(newStatus).every((value) => value)) {
      handleFinishChallenge(newStatus);
    }
  };

  const getObtainedXp = useCallback(
    (answers: ChallengeAnswers) =>
      steps.reduce((acc, step, index) => {
        if (![0, 3, 4, 5].includes(index)) return acc;
        const key = keyPerStep[index];
        const question = step as QuestionStepType;
        return (
          acc +
          (answers[key] === question.answer ? correctAnswerXp : wrongAnswerXp)
        );
      }, 0),
    [steps]
  );

  const handleFinishChallenge = useCallback(
    async (answers: ChallengeAnswers) => {
      const buildAnswers = (key: keyof typeof keyPerStep): QuestionResult => {
        const expectedAnswer = (steps[key as any] as QuestionStepType).answer;
        const question = keyPerStep[key as any];
        const answer = answers[question];
        return {
          answer,
          expectedAnswer,
          correct: answer === expectedAnswer,
        };
      };

      const answersToSend = {
        main: buildAnswers(0),
        first: buildAnswers(3),
        second: buildAnswers(4),
        third: buildAnswers(5),
      };

      const correctAnswers = Object.values(answersToSend).reduce(
        (acc, answer) => (answer.correct ? acc + 1 : acc),
        0
      );

      const obtainedXp = getObtainedXp(answers);
      const message = getChallengeResultMessage(correctAnswers, obtainedXp);

      await answerChallenge({
        id: challenge.id,
        title: challenge.title,
        finishedAt: Timestamp.now(),
        obtainedXp,
        answers: answersToSend,
        isExtra: false,
        correctAnswers,
      });

      openDialog({
        title: message.title,
        body: message.body,
        mainButton: {
          label: "Voltar para desafios",
          onClick: () => {
            navigate("/desafios", { replace: true });
          },
        },
      });
    },
    [steps, getObtainedXp, challenge, openDialog, navigate]
  );

  const handleStepChange = (newStep: number) => {
    setStep({ max: Math.max(step.max, newStep), current: newStep });
    window.scrollTo(0, 0);
  };

  return (
    <Stack spacing={2} mt={2}>
      <Stepper
        activeStep={step.current}
        alternativeLabel
        sx={{ overflow: "auto" }}
      >
        {steps.map((_step, index) => (
          <Step key={index}>
            <StepLabel>{getTitlePerStep(index)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper sx={{ padding: 2, borderRadius: 2 }}>
        {steps[step.current].type === "question" ? (
          <QuestionStep
            question={steps[step.current] as QuestionStepType}
            handleStepChange={handleStepChange}
            currentStep={step.current}
            handleUpdateStatus={handleUpdateStatus}
            key={step.current}
            onlyView={onlyView}
            predefinedAnswer={initialAnswers[keyPerStep[step.current]]}
          />
        ) : (
          <ExplanationStep
            explanation={steps[step.current] as ExplanationStepType}
            handleStepChange={handleStepChange}
            currentStep={step.current}
            key={step.current}
            maxStep={step.max}
          />
        )}
      </Paper>
    </Stack>
  );
};

export default ChallengeStepper;
