import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7849B3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#332443",
    },
    background: {
      default: "#d3d3d3",
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          alignSelf: "center",
        },
      },
    },
  },
});

export default theme;
