import { Box } from "@mui/material";
import Header from "components/Header";
import PageContainer from "components/PageContainer";
import { Outlet } from "react-router-dom";

export const PrivateNavigator = () => {
  return (
    <Box margin={-1}>
      <Header />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </Box>
  );
};
