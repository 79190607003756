import { Avatar, Button, CircularProgress, useTheme } from "@mui/material";
import BasePage from "components/BasePage";
import { updateAvatar } from "lib/firebase/students";
import useProfileQuery from "queries/useProfileQuery";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { avatarList, getAvatarSrc } from "utils/avatars";
import Grid from "@mui/material/Unstable_Grid2";
import DialogDescription from "components/DialogDescription";

const AvatarSelector = () => {
  const { data, loading } = useProfileQuery();
  const navigate = useNavigate();
  const [selectedAvatar, setSelectedAvatar] = useState<string>("");

  useEffect(() => {
    setSelectedAvatar(data?.firestore?.avatar ?? "");
  }, [data?.firestore?.avatar]);

  const theme = useTheme();

  const handleUpdateAvatar = () => {
    updateAvatar(selectedAvatar);
    navigate("/");
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <BasePage
      title="Alterar avatar"
      description={
        <DialogDescription
          regular="Nesta tela, você pode personalizar o seu avatar, que será a representação do seu perfil no ranking e será visível para todos os outros alunos."
          bold="Escolha um avatar que represente você e mostre seu estilo único durante sua jornada de aprendizado!"
        />
      }
    >
      <Grid container spacing={2}>
        {avatarList.map((option) => (
          <Grid key={option} xs={6} md={3}>
            <Avatar
              src={getAvatarSrc(option)}
              sx={{
                width: 160,
                height: 160,
                cursor: "pointer",
                outline:
                  selectedAvatar === option
                    ? `5px solid ${theme.palette.primary.main}`
                    : `5px solid ${theme.palette.divider}`,
              }}
              component="a"
              onClick={() => setSelectedAvatar(option)}
            />
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        fullWidth
        onClick={handleUpdateAvatar}
        sx={{ marginTop: 3 }}
      >
        Salvar
      </Button>
    </BasePage>
  );
};

export default AvatarSelector;
