export interface ChallengeAnswers {
  main: string;
  first: string;
  second: string;
  third: string;
}

export const initialAnswers: ChallengeAnswers = {
  main: "",
  first: "",
  second: "",
  third: "",
};

export const keyPerStep: Record<number, keyof ChallengeAnswers> = {
  0: "main",
  3: "first",
  4: "second",
  5: "third",
};

export const getTitlePerStep = (step: number) => {
  switch (step) {
    case 0:
      return "Início";
    case 1:
      return "Conceito";
    case 2:
      return "Conteúdo";
    default:
      return "Exercício";
  }
};

export const getChallengeResultMessage = (questions: number, xp: number) => {
  let message: {
    title: string;
    body: React.ReactNode;
  };

  switch (questions) {
    case 4:
      message = {
        title: "Incrível!",
        body: `Você completou o desafio, acertou todas as 4 questões e ganhou ${xp} XP! Continue assim e alcance ainda mais conquistas!`,
      };
      break;
    case 3:
      message = {
        title: "Muito bem!",
        body: `Você completou o desafio, acertou 3 das 4 questões e ganhou ${xp} XP! Está quase lá! Continue praticando e logo você vai acertar todas.`,
      };
      break;
    case 2:
      message = {
        title: "Você está no caminho certo!",
        body: `Você completou o desafio, acertou 2 das 4 questões e ganhou ${xp} XP! Com mais um pouco de prática, você vai melhorar ainda mais.`,
      };
      break;
    case 1:
      message = {
        title: "Não desanime!",
        body: `Você completou o desafio, acertou 1 das 4 questões e ganhou ${xp} XP! Cada erro é uma oportunidade de aprendizado. Continue praticando e você vai melhorar!`,
      };
      break;
    case 0:
      message = {
        title: "Cada começo é um desafio!",
        body: `Você completou o desafio, mas desta vez não acertou nenhuma das questões. Não se preocupe, você ganhou ${xp} XP! Use essa experiência para aprender e melhorar nos próximos desafios.`,
      };
      break;
    default:
      message = {
        title: "Resultado",
        body: `Você completou o desafio e ganhou ${xp} XP.`,
      };
      break;
  }

  return message;
};
