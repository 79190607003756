import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
} from "@mui/material";
import { useDialog } from "hooks/useDialog";
import React from "react";

const Dialog = () => {
  const { dialog, closeDialog } = useDialog();
  const { visible, title, body, mainButton, secondaryButton } = dialog;

  const showActions = !!mainButton || !!secondaryButton;

  return (
    <MuiDialog
      open={visible}
      onClose={closeDialog}
      PaperProps={{ sx: { borderRadius: 2, boxShadow: 2 } }}
      maxWidth="sm"
    >
      <DialogTitle sx={{ alignSelf: "center" }}>{title}</DialogTitle>
      <Box p={2}>
        {typeof body === "string" ? (
          <DialogContentText sx={{ alignSelf: "center" }}>
            {body}
          </DialogContentText>
        ) : (
          body
        )}
      </Box>
      {showActions && (
        <DialogActions sx={{ mt: 2 }}>
          {secondaryButton && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                secondaryButton.onClick && secondaryButton.onClick();
                closeDialog();
              }}
            >
              {secondaryButton.label}
            </Button>
          )}
          {mainButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                mainButton.onClick && mainButton.onClick();
                closeDialog();
              }}
            >
              {mainButton.label}
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
