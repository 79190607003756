import { collection, doc, getDocs, query } from "firebase/firestore";
import { assignTypes, db } from "lib/firebase";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { ChallengeResult, ClassDb, ReportData } from "./types";
import { format } from "date-fns";
import theme from "components/theme";
import { getStudent } from "./students";
import { getStudentsByClass } from "./ranking";
import { getClass } from "./classes";

const convertChalengeResultToReport = (
  result: ChallengeResult,
  name?: string
): ReportData => {
  const getCorrectRatio = (result: ChallengeResult) => {
    const correctRatio =
      Object.values(result.answers).reduce(
        (acc, result) => acc + (result.correct ? 1 : 0),
        0
      ) / 4;

    return correctRatio;
  };

  const formatDate = (date: Date) => format(new Date(date), "dd/MM/yyyy HH:mm");

  return {
    NOME: name,
    DESAFIO: result.title ?? "",
    QI: result.answers.main.answer,
    Q1: result.answers.first.answer,
    Q2: result.answers.second.answer,
    Q3: result.answers.third.answer,
    "%": getCorrectRatio(result) * 100,
    "GAB QI": result.answers.main.expectedAnswer,
    "GAB Q1": result.answers.first.expectedAnswer,
    "GAB Q2": result.answers.second.expectedAnswer,
    "GAB Q3": result.answers.third.expectedAnswer,
    TIPO: result.isExtra ? "Especial" : "Regular",
    "DATA/HORA": formatDate(result.finishedAt.toDate()),
  };
};

const generateReport = (
  title: string,
  data: ReportData[],
  showName: boolean = false
) => {
  const doc = new jsPDF({ orientation: "landscape" });

  const formattedData = data.map((row) => {
    if (!showName) {
      delete row.NOME;
    }

    return row;
  });

  doc.text(`Relatorio BUSHIDO - ${title}`, 14, 16);
  const tableColumns = Object.keys(formattedData[0]);
  const tableRows = formattedData.map((row) => Object.values(row));

  autoTable(doc, {
    head: [tableColumns],
    body: tableRows,
    startY: 20,
    headStyles: { fillColor: theme.palette.primary.main },
    theme: "grid",
  });

  doc.addPage();

  doc.text("Legenda", 14, 16);
  doc.text("QI: Questão Inicial", 14, 26);
  doc.text("Q1/2/3: Questão 1/2/3", 14, 36);
  doc.text("%: Porcentagem de acertos", 14, 46);
  doc.text("GAB QI: Gabarito Questão Inicial", 14, 56);
  doc.text("GAB Q1/2/3: Gabarito Questão 1/2/3", 14, 66);
  doc.text("TIPO: Tipo de questão", 14, 76);
  doc.text("DATA/HORA: Data e hora de finalização", 14, 86);

  doc.save(`${title} - Relatorio BUSHIDO.pdf`);
};

export const getStudentReportData = async (email?: string, name?: string) => {
  const dbEmail = email ?? sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const challengesQuery = query(
    collection(db, "students", dbEmail, "challenges").withConverter(
      assignTypes<ChallengeResult>()
    )
  );

  const challengesDocs = await getDocs(challengesQuery);

  const results = challengesDocs.docs.map((doc) => doc.data());

  return results.map((result) => convertChalengeResultToReport(result, name));
};

export const generateStudentReport = async (email?: string) => {
  const data = await getStudentReportData(email);
  const profile = await getStudent(email);

  generateReport(profile?.name ?? "", data);
};

export const getClassReportData = async (classId: string) => {
  const classRef = doc(db, "classes", classId).withConverter(
    assignTypes<ClassDb>()
  );

  const classData = await getClass(classId);

  const students = await getStudentsByClass(classRef);

  let data: ReportData[] = [];

  for (const student of students) {
    const studentData = await getStudentReportData(student.email, student.name);
    data = [...data, ...studentData];
  }

  generateReport(classData?.name ?? "", data, true);
};
