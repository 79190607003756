import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";

interface DialogDescriptionProps {
  regular: string;
  bold?: string;
}

const DialogDescription: FC<DialogDescriptionProps> = ({ regular, bold }) => {
  return (
    <Stack spacing={1}>
      <Typography>{regular}</Typography>
      {bold && <Typography fontWeight="bold">{bold}</Typography>}
    </Stack>
  );
};

export default DialogDescription;
