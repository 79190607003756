import BasePage from "components/BasePage";
import { useAuth } from "contexts/Authenticator";
import React from "react";
import StudentHome from "./StudentHome";
import Classes from "components/Classes";
import DialogDescription from "components/DialogDescription";

const Home = () => {
  const { isTeacher } = useAuth();

  return (
    <BasePage
      title="Início"
      showBackButton={false}
      description={
        <DialogDescription
          regular={
            isTeacher
              ? "Nesta tela, você pode visualizar todas as turmas que já criou e criar novas turmas, organizando-as para oferecer o melhor suporte durante suas jornadas de aprendizado."
              : "Nesta tela, você encontrará todas as suas informações de usuário, incluindo seu nível, título, e avatar. Aqui, você pode personalizar seu avatar e visualizar todas as medalhas que já conquistou em sua jornada. Além disso, pode baixar um relatório que contém dados sobre seus desafios e pode lhe ajudar nos seus estudos."
          }
          bold={
            isTeacher
              ? "Use essas ferramentas para gerenciar e guiar suas turmas rumo ao sucesso!"
              : "Continue explorando para subir de nível e colecionar ainda mais conquistas!"
          }
        />
      }
    >
      {!isTeacher && <StudentHome />}
      {isTeacher && <Classes />}
    </BasePage>
  );
};

export default Home;
