import { getChallengesWithResults } from "./challenges";
import { getRankingData } from "./ranking";
import { StudentDb, StudentStats } from "./types";

const calculateBelowPercentage = (list: StudentDb[], email: string): number => {
  const userIndex = list.findIndex((student) => student.email === email);
  const belowCount = list.length - userIndex - 1;
  const belowPercentage = (belowCount / list.length) * 100;

  return belowPercentage;
};

export const getStats = async (): Promise<StudentStats> => {
  const { general, sameClass } = await getRankingData();

  const email = sessionStorage.getItem("@AuthFirebase:email") ?? "";

  const generalPercentage = calculateBelowPercentage(general, email);
  const sameClassPercentage = calculateBelowPercentage(sameClass, email);

  const challenges = await getChallengesWithResults();

  const solvedChallenges = challenges.filter((challenge) => challenge.result);

  const answeredQuestions = solvedChallenges.reduce(
    (acc, challenge) => acc + (challenge.result?.correctAnswers ?? 0),
    0
  );

  return {
    challenges: {
      value: solvedChallenges.length,
      total: challenges.length,
      percentage: (solvedChallenges.length / challenges.length) * 100,
    },
    xp: {
      generalPercentage,
      sameClassPercentage,
    },
    questions: {
      value: answeredQuestions,
      total: solvedChallenges.length * 4,
      percentage: (answeredQuestions / (solvedChallenges.length * 4)) * 100,
    },
  };
};
