import {
  IconButton,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDialog } from "hooks/useDialog";

interface BasePageProps extends StackProps {
  title?: string;
  showBackButton?: boolean;
  description?: React.ReactNode;
}

const BasePage = ({
  children,
  title,
  showBackButton = true,
  description,
  ...props
}: BasePageProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { openDialog } = useDialog();

  const openAboutDialog = () => {
    if (!title || !description) return;

    openDialog({
      title,
      body: description,
      mainButton: {
        label: "Voltar",
      },
    });
  };

  return (
    <Stack
      maxWidth="768px"
      width="100%"
      p={2}
      mt={2}
      borderRadius={4}
      spacing={3}
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
      {...props}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        {showBackButton && (
          <IconButton aria-label="Voltar" onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {title && (
          <Typography variant="h4" color={theme.palette.secondary.main}>
            {title}
          </Typography>
        )}
        {description && (
          <IconButton aria-label="Sobre" onClick={openAboutDialog}>
            <HelpOutlineIcon />
          </IconButton>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default BasePage;
