import { initializeApp, getApps, FirebaseOptions } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  DocumentData,
  QueryDocumentSnapshot,
  getFirestore,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCOuTe99fO7KKIpwzxa8WcwYBLfEQCSo_w",
  authDomain: "bushido-app.firebaseapp.com",
  projectId: "bushido-app",
  storageBucket: "bushido-app.appspot.com",
  messagingSenderId: "137733324049",
  appId: "1:137733324049:web:b9f762d48264d2985260b0",
};

export const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const auth = getAuth(firebaseApp);

export const db = getFirestore(firebaseApp);

export const storage = getStorage(firebaseApp);

export function assignTypes<T extends object>() {
  return {
    toFirestore(doc: T): DocumentData {
      return doc;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      return snapshot.data()! as T;
    },
  };
}
