import { client } from "../contentful";
import { ChallengeSkeleton, DetailedChallenge } from "./types";

export const getDetailedChallenge = async (
  id: string
): Promise<DetailedChallenge | undefined> => {
  try {
    const entry = await client.getEntry<ChallengeSkeleton>(id);

    if (
      !("fields" in entry.fields.mainQuestion) ||
      !("fields" in entry.fields.concept) ||
      !("fields" in entry.fields.content)
    ) {
      return;
    }

    return {
      id: entry.sys.id,
      title: entry.fields.title,
      description: entry.fields.description,
      mainQuestion: entry.fields.mainQuestion.fields,
      concept: entry.fields.concept.fields,
      content: entry.fields.content.fields,
      questions: entry.fields.questions
        .map((question) => "fields" in question && question.fields)
        .filter(Boolean) as DetailedChallenge["questions"],
      theme: entry.fields.theme,
      year: entry.fields.year,
      image:
        entry.fields.image && "fields" in entry.fields.image
          ? entry.fields.image.fields?.file?.url
          : undefined,
      ability: entry.fields.ability,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getChallenges = async (): Promise<DetailedChallenge[]> => {
  const entries = await client.getEntries<ChallengeSkeleton>({
    content_type: "challenge",
  });

  return entries.items
    .map((entry) => {
      if (
        !("fields" in entry.fields.mainQuestion) ||
        !("fields" in entry.fields.concept) ||
        !("fields" in entry.fields.content)
      ) {
        return undefined;
      }

      return {
        id: entry.sys.id,
        title: entry.fields.title,
        description: entry.fields.description,
        mainQuestion: entry.fields.mainQuestion?.fields ?? undefined,
        concept: entry.fields.concept.fields ?? undefined,
        content: entry.fields.content.fields ?? undefined,
        questions: entry.fields.questions
          .map((question) => "fields" in question && question.fields)
          .filter(Boolean) as DetailedChallenge["questions"],
        theme: entry.fields.theme,
        year: entry.fields.year,
        image:
          entry.fields.image && "fields" in entry.fields.image
            ? entry.fields.image.fields?.file?.url
            : undefined,
        ability: entry.fields.ability,
      };
    })
    .filter(Boolean) as DetailedChallenge[];
};
