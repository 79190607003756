export enum Avatar {
  bear = "bear",
  capybara = "capybara",
  penguin = "penguin",
  pufferfish = "pufferfish",
  shark = "shark",
  heron = "heron",
  lobster = "lobster",
  beetle = "beetle",
}

export const avatarList = Object.values(Avatar);

export const getAvatarSrc = (avatar: string) => `/avatars/${avatar}.jpeg`;
