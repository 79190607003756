import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";

const PageContainer: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(/background.jpeg)`,
        minHeight: "100vh",
        justifyContent: "center",
        display: "flex",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
